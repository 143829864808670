// variables
@import "../../../Assets/Styles/Variables.scss";
//Variables end

.animationTopToBottom {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 100% top;
  transform-origin: 100% top;
  -webkit-transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s,
    -webkit-transform 0.12s linear 0.12s;
  transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s,
    -webkit-transform 0.12s linear 0.12s;
  transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s,
    transform 0.12s linear 0.12s;
  transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s,
    transform 0.12s linear 0.12s, -webkit-transform 0.12s linear 0.12s;
  visibility: hidden;
}

.contactTopTitleSec {
  padding: 2px 0 20px 0;

  .backScreen {
    background-image: url("../../../Assets/Images/right-angle-grey.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 2px;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #7d8a9a;
  }

  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #122a46;
    margin: 0;
  }

  .rightBtnGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: row-reverse;
      justify-content: flex-start;
    }

    .moreActionLinks {
      padding-right: 10px;

      .k-button-outline {
        color: $primary-dark-blue;
        padding: 5px 22px 5px 0;
        box-shadow: none !important;
        cursor: pointer;
        font-weight: 500;
        background-image: url("../../../Assets/Images/down-angle-blue.svg");
        background-repeat: no-repeat;
        background-position: center right;
        border: none;
        transition: 300ms ease all;

        &::after,
        &::before {
          box-shadow: none;
          outline: none;
          background-color: transparent;
        }

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
          background-color: transparent;

          &::after,
          &::before {
            box-shadow: none;
            outline: none;
            background-color: transparent;
          }
        }
      }

      .dropdown-menu {
        background: #ffffff;
        border: 1px solid rgba(210, 218, 227, 0.75);
        box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
        border-radius: 5px;
        padding: 10px 0;
        list-style: none;
        margin: 0;
        overflow: hidden;
        min-width: inherit;
        display: block;
        @extend .animationTopToBottom;
        @media screen and (max-width: 767px) {
          right: 0;
        }
        li {
          a {
            color: $primary-dark-blue;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.3px;
            padding: 8px 20px;
            display: inline-block;
            width: 100%;

            &:hover {
              background-color: #f7f8f9;
            }
          }
        }
      }

      &:hover {
        .k-button-outline {
          background-image: url("../../../Assets/Images/up-angle-blue.svg");
        }

        .dropdown-menu {
          opacity: 1;
          -webkit-transform: scaleY(1);
          transform: scaleY(1);
          visibility: visible;
        }
      }
    }
  }
}

.contactPageTitleSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;

  h3 {
    font-weight: 300;
    color: $dark-grey;
    line-height: 22px;
    margin: 0;
  }

  .addRoleBtn {
    .btn-link {
      background-image: url("../../../Assets/Images/pluse-circle-blue.svg");
      background-repeat: no-repeat;
      background-position: top -1px left;
      background-size: 22px;
      padding-left: 28px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

//ADD CONTACT DETAILS MODEL

.tooltip-text {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
}

.tooltip-text-start-left-side {
  .k-tooltip-content {
    text-align: inherit !important;
  }
}

.addContactDetailsModel {
  .contactDetailsInner {
    height: calc(100% - 70px) !important;
    width: 100%;

    .contactDetailsGroup {
      padding-right: 15px;
    }

    .noDataCreateTrip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      margin-top: 20px;

      img {
        width: 32px;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        color: $text-dark-black-color;
        margin: 20px 0;
      }

      .btn-link {
        font-weight: 600;
        font-size: 16px;
        color: $primary-light-blue;
      }
    }
  }

  .k-dialog {
    position: fixed;
    right: 0;
    width: 100%;
    max-width: 550px;
    top: 0;
    bottom: 0;
    border-radius: 0;
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(127, 135, 146, 0.25);

    .modelCustomTitle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;

      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.2em;
        color: $text-dark-black-color;
        margin: 0;
        text-transform: uppercase;
      }
    }

    .k-window-actions,
    .k-dialog-titlebar-actions {
      margin: 0;

      .k-button {
        opacity: 1;
        padding: 0;

        &:focus,
        &:hover {
          box-shadow: none !important;
          background: none !important;

          &:before,
          &:after {
            display: none !important;
          }
        }

        .k-button-icon {
          background: url("../../../Assets/Images/close-black.svg") !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: 16px !important;

          &::before {
            display: none;
          }
        }
      }
    }

    .modelFooterAction {
      text-align: right;
      padding: 24px;
      box-shadow: 0px 0px 20px rgba(145, 148, 165, 0.25);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background: #fff;
      gap: 10px;
      display: flex;
      justify-content: flex-end;

      .k-button {
        font-weight: 600;
      }
    }

    .k-window-content {
      // padding: 0px 24px 24px 24px;
      padding: 0px 9px 24px 24px;
    }

    .k-dialog-titlebar {
      border: none;
      padding: 15px 20px 15px 24px;

      .k-window-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $text-dark-black-color;
      }

      .k-button {
        opacity: 1;
        padding: 0;

        .k-button-icon {
          background: url("../../../Assets/Images/close-black.svg") !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: 16px !important;
          opacity: 1;

          &::before {
            display: none;
          }
        }
        .k-svg-icon.k-svg-i-x svg {
          visibility: hidden;
        }
        &:hover {
          background-color: transparent;

          &::before,
          &::after {
            display: none !important;
          }
        }
      }
    }

    .viewProfileSubHeader {
      border-bottom: 1px solid #d2dae370;
      padding-bottom: 15px;
      margin-bottom: 15px;

      .profileUserName {
        font-weight: 300;
        font-size: 36px;
        line-height: 33px;
        color: $primary-dark-blue;
      }

      .userTitleRole {
        font-weight: 400;
        font-size: 24px;
        line-height: 22px;
        color: $text-dark-black-color;
        padding-top: 5px;
      }
    }

    .userDetailsInfo {
      border-bottom: 1px solid rgba(210, 218, 227, 0.4392156863);
      padding-bottom: 5px;
      margin-bottom: 15px;

      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: $label-color;
        display: inline-block;
        width: 100%;

        .customBadge {
          margin-left: 5px;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: $text-dark-black-color;
        margin-top: 5px;
        display: inline-block;
        width: 100%;
        margin-bottom: 22px;

        .leadBtn {
          // background-image: url('../../../Assets/Images/blue-circle.svg') !important;
          background-repeat: no-repeat;
          background-position: center left 10px;
          background-size: 8px;
          background-color: #e1e4f3;
          border: 0.74359px solid $primary-dark-blue;
          border-radius: 4.46154px;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: $primary-dark-blue;
          padding: 6px 15px 6px 25px;
          display: inline-block;
        }
      }
    }

    .contactOverviewPanel {
      padding-top: 10px;
      padding-bottom: 15px;

      .k-panelbar {
        border: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;

        .k-panelbar-header {
          border: 1px solid #7d8a9a70;
          border-radius: 5px;
        }

        .k-panelbar-item {
          padding: 20px;

          .k-link,
          .k-selected {
            font-weight: 500;
            font-size: 18px;
            line-height: 17px;
            color: $text-dark-black-color;
            background-color: transparent;
            padding: 0;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
              box-shadow: none;
              outline: none;
              background-color: transparent;
            }

            .k-panelbar-item-text {
              width: 100%;

              .contactPanelTitleSec {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
          }

          .k-focus {
            box-shadow: none;
          }
        }

        .k-expanded {
          .k-link {
            padding-bottom: 0;
          }
        }
      }

      .overviewDetailsCard {
        border-top: 1px solid #d2dae370;
        padding-top: 5px;
        margin-top: 15px;
        height: 180px !important;

        // overflow: auto;
        // -ms-overflow-style: thin;
        // scrollbar-width: thin;
        // -webkit-scrollbar: thin;
        > div:nth-child(1) {
          overflow-x: hidden !important;
        }
        .tasksRow {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 12px 0;

          .tasksInfoDate {
            padding-right: 10px;

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 13px;
              color: $text-dark-black-color;
              margin-bottom: 7px;
            }

            .dateAndTime {
              font-weight: 500;
              font-size: 12px;
              line-height: 11px;
              background-position: center left;
              background-repeat: no-repeat;
              background-size: 18px;
              padding: 4px 0 4px 23px;
            }
          }

          .customBadge {
            padding: 6px 10px 5px 10px;
            margin-left: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }

        .taskDue {
          .tasksInfoDate {
            .dateAndTime {
              color: #d14141;
              background-image: url("../../../Assets/Images/calendarRed.svg");
            }
          }

          .customBadge {
            background: #ffd1d1;
            color: #d14141;
          }
        }

        .taskUpcoming {
          .tasksInfoDate {
            .dateAndTime {
              color: $label-color;
              background-image: url("../../../Assets/Images/calendarGrey.svg");
            }
          }
        }

        .routeCol .routesInfo {
          color: #001489;
          font-size: 16px;
          font-weight: 600;
        }

        .dates {
          color: #001489;
          font-size: 14px;
          font-weight: 600;
        }
        .tripTypePill {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          min-width: 70px;
          height: 20px;
          background-color: #e6e9f6;
          border-radius: 20px;
          font-size: 10px;
          font-weight: 600;
        }
        .openTaskDashboard {
          .statusBx {
            display: inline-block;
            padding: 6px 10px;
            border-radius: 30px;
            text-align: center;
            font-size: 10px;
            font-weight: 600;
            line-height: normal;
          }

          .actionDueBx {
            @extend .statusBx;
            background: #e6e9f6;
            color: #00106e;
          }

          .actionOverdueBx {
            @extend .statusBx;
            background: #fdebeb;
            color: #d14141;
          }
          .taskItems {
            border-bottom: 0.5px solid rgba(210, 218, 227, 0.75);
            margin-bottom: 16px;
            padding-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }

            .leadText {
              color: #00041b;
              font-size: 16px;
              font-weight: 400;
              line-height: 18px;
            }

            .taskStatus {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 12px;

              .leftInfo {
                display: flex;
                align-items: center;
                gap: 4px;

                .calendarIcon {
                  width: 13px;
                  height: 13px;
                  background-position: 0 -1px;
                  background-size: 12px;
                }

                .dueDate {
                  margin-bottom: 0;
                  color: #00041b;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: normal;
                }
              }

              .actionDue {
                display: flex;
                align-items: center;
                gap: 10px;
              }
            }

            .checkCircleGreyIcon {
              width: 18px;
              height: 18px;
              background-size: 18px;
            }
            .checkCircleGreenIcon {
              width: 18px;
              height: 18px;
              background-size: 18px;
            }
          }
        }
      }

      .k-animation-container {
        border-radius: 0;
      }
    }
  }

  .contactsPanels {
    padding-top: 10px;
    padding-bottom: 15px;
    height: calc(100% - 75px) !important;

    .k-panelbar {
      border: none;
      padding-right: 15px;

      .k-panelbar-header {
        border: none;
      }

      .k-panelbar-item {
        &:first-child {
          .k-link {
            padding-top: 0;
          }
        }

        .k-link {
          font-weight: 500;
          font-size: 18px;
          line-height: 17px;
          color: $text-dark-black-color;
          background-color: #fff;
          padding: 1rem 0rem;
          cursor: pointer;

          &:hover,
          &:focus,
          &:active {
            box-shadow: none;
            outline: none;
            background-color: transparent;
          }
        }

        .k-focus {
          box-shadow: none;
        }
      }

      .k-expanded {
        .k-link {
          padding-bottom: 0;
        }
      }
    }

    .maverickForm {
      padding-bottom: 15px;

      h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        color: $text-dark-black-color;
        margin-bottom: -5px;
      }

      .checkListSec {
        padding-top: 24px;

        label.checkLabel {
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          display: block;
          padding-bottom: 5px;
        }

        .checkboxList span {
          margin-right: 30px;
        }
      }

      hr {
        border-color: #e6e9f7;
        margin-top: 22px;
        margin-bottom: 15px;
        opacity: 1;
      }
    }
  }

}
.associated-trip-slider-Modal .k-dialog .contactOverviewPanel .overviewDetailsCard{
  height: auto !important;
}

.editContactModal {
  .maverickForm {
    padding-right: 15px;
    padding-top: 10px;

    .contactRadio {
      padding-top: calc(1.5 * 1rem);
      min-height: 76px;
      display: flex;
      align-items: center;
    }

    .addRoleBtn {
      .btn-link {
        font-size: 12px;
      }
    }
  }
}

.userDetailsWrapper {
  .userSortInfoCard {
    margin-bottom: 15px;

    .userPicTitleSec {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;

      .uploadUserPic {
        position: relative;

        .uploadProfilePic {
          background-image: url("../../../Assets/Images/file-upload-blue.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 14px;
          width: 24px;
          height: 24px;
          display: inline-block;
          background-color: #fff;
          border: 1px solid $primary-light-blue;
          border-radius: 100%;
          position: absolute;
          padding: 0;
          right: -1px;
          bottom: -2px;
        }

        img {
          // max-width: 77px;
          // max-height: 77px;
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 100%;
          overflow: hidden;
        }

        .profileDefaultImg {
          border: 1px solid $primary-light-blue;
        }
      }

      .uploadTopInfoSec {
        padding-left: 8px;
        max-width: calc(100% - 64px);

        label {
          font-weight: 500;
          font-size: 12px;
          line-height: 11px;
          color: $label-color;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        h4 {
          font-weight: 300;
          font-size: 22px;
          line-height: 28px;
          color: #2d3f59;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 3px;
        }

        .leadBtn {
          background-color: #e1e4f3;
          border: 0.74359px solid $primary-dark-blue;
          border-radius: 4.46154px;
          font-weight: 400;
          font-size: 11.8974px;
          line-height: 11px;
          color: #2d3f59;
          padding: 5px 8px;
          display: inline-block;
          position: relative;

          &::before {
            content: "";
            background: #2d3f59;
            height: 6px;
            width: 6px;
            display: none;
            border-radius: 100px;
            margin-right: 8px;
          }
        }
      }
    }

    .userContactInfo {
      p {
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;

        img {
          width: 15px;
          margin-top: 3px;
          max-height: 15px;
        }

        label {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: $text-dark-black-color;
          margin: 0;
          padding-left: 10px;
        }

        a {
          color: $primary-dark-blue;
          font-weight: 600;
          display: flex;
          align-items: center;
        }

        span.copyLink {
          background-image: url("../../../Assets/Images/copyIcon-blue.svg") !important;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 14px;
          width: 16px;
          height: 16px;
          display: inline-block;
          margin-left: 4px;
          cursor: pointer;
        }
      }
    }

    .userTripInfoSec, .userSalesmateLink {
      p {
        display: flex;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 18px;
        justify-content: space-between;

        span {
          color: $label-color;
          font-weight: 400;
        }

        label {
          font-weight: 500;
          color: $text-dark-black-color;
          margin: 0;
          padding-left: 10px;
          text-align: right;

          strong {
            font-weight: 500;
            display: block;
          }
        }
      }
    }

    hr {
      border-color: rgba(93, 122, 141, 0.6);
    }
  }

  .pinned-notes-wrapper {
    // max-height: 150px;
    // overflow-y: scroll;
    .pinned-notes-section {
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(127, 135, 146, 0.15);
      border-radius: 8px;
      padding: 14px;
      margin-bottom: 16px;

      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      align-items: center;

      .pinnednotes-icon {
        align-self: start;
        margin-right: 12px;
      }

      .pinnednotes-description {
        word-break: break-word;

        .pinnednote-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: #00041b;
          margin-bottom: 0;
        }

        .pinnednote-date {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #82869b;
          margin-bottom: 0;
        }
      }
    }
  }
}

// Upload Profile Image Modal
.uploadProfileImgModel,
.customSmallModel {
  .k-dialog {
    width: 100%;
    max-width: 500px;
    border-radius: 0;
    border: none;
    background: #ffffff;
    box-shadow: 0px -10px 30px rgba(145, 148, 165, 0.25);

    .k-dialog-titlebar {
      border: none;
      padding: 25px 25px 0 30px;

      .k-dialog-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.2em;
        color: $text-dark-black-color;
      }
    }

    .k-dialog-titlebar-actions {
      margin: 0;

      .k-button {
        opacity: 1;
        padding: 0;

        .k-button-icon {
          background: url("../../../Assets/Images/close-black.svg") !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: 16px !important;
          opacity: 1;

          &::before {
            display: none;
          }
        }

        &:hover {
          background-color: transparent;

          &::before,
          &::after {
            display: none !important;
          }
        }
      }
    }

    .k-window-content {
      padding: 30px;
    }

    .modelFooterAction {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      padding-top: 30px;
      gap: 10px;

      .k-button {
        font-weight: 700;
        font-size: 14px;
      }

      .k-button-solid {
        min-width: 140px;
      }
    }
  }
}

.modelRounded {
  .k-dialog {
    border-radius: 5px;
    overflow: hidden;
  }
}

//Model End

.viewIdetityDocModel {
  .k-dialog {
    width: auto !important;
    max-width: 1100px !important;

    .k-dialog-content {
      max-height: calc(100vh - 100px);
    }
  }
}

.userSortInfoCard {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(127, 135, 146, 0.15);
  border-radius: 8px;
  padding: 20px;
}

.screenTitle {
  font-weight: 300;
  color: $dark-grey;
}

.userProfileSec {
  padding: 30px 40px;
  border: 0.5px solid rgba(210, 218, 227, 0.75);
  border-radius: 5px;
  box-shadow: none;
  margin-top: 15px;
  margin-bottom: 20px;

  .userPicTitleSec {
    border-right: 1px solid #dde1f6;

    .uploadUserPic {
      img {
        width: 130px;
        max-width: inherit !important;
        max-height: inherit !important;
      }

      .uploadProfilePic {
        background-size: 20px !important;
        width: 36px !important;
        height: 36px !important;
        border-width: 2px !important;
        right: 5px !important;
        bottom: 5px !important;
      }
    }

    .userNameId {
      padding-left: 35px;

      h1 {
        color: $dark-grey;
        font-weight: 300;
        font-size: 38px;
        line-height: 35px;
        margin-bottom: 18px;
      }

      p {
        font-weight: 300;
        font-size: 32px;
        line-height: 29px;
        color: $label-color;
        margin: 0;
      }
    }
  }

  .userMoreDetails {
    padding-left: 10px;

    label {
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: $label-color;
      margin-bottom: 10px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: $dark-grey;
    }
  }
}

.updateProfileInfo {
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  color: $dark-grey;

  strong {
    color: $primary-light-blue;
    font-weight: 600px;
  }
}

.pinnedSec {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(127, 135, 146, 0.15);
  border-radius: 8px;
  padding: 12px;
  margin-top: 15px;
  display: flex;

  .pinIcon {
    background: url("../../../Assets/Images/pin-blue.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% !important;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
  }

  .pinData {
    padding-left: 12px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: $text-dark-black-color;
      margin-bottom: 5px;
    }

    .pinDate {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $label-color;
    }
  }
}

.userDetailSec {
  margin-bottom: 30px;

  .userTitleSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 10px 0;

    h4 {
      font-weight: 300;
      font-size: 24px;
      line-height: 22px;
      color: $text-dark-black-color;
      margin-bottom: 0;
    }

    .editSecBtn {
      background: url("../../../Assets/Images/pencil-blue.svg") !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 16px !important;
      font-size: 0px;
      width: 30px;
      height: 30px;
      padding: 0;

      &:focus,
      &:hover,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &:last-child {
    margin-bottom: 5px;
  }
}

.userSecData {
  background: #ffffff;
  border: 0.5px solid rgba(210, 218, 227, 0.75);
  border-radius: 5px;
  // padding: 12px 20px 0px 20px;
  padding: 12px 20px 12px 20px;

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: $label-color;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: $text-dark-black-color;
    margin: 5px 0 20px 0;
    word-wrap: break-word;

    small {
      font-size: 10px;
      line-height: 9px;
      color: rgba(111, 116, 142, 0.8);
      padding-left: 2px;
    }

    a {
      font-weight: 600;
      color: $primary-light-blue;
    }
  }

  .statusInfo {
    color: $primary-dark-blue;
    background: url("../../../Assets/Images/blue-circle.svg");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 8px;
    padding-left: 18px;
  }

  hr {
    border-color: rgba(93, 122, 141, 0.3);
    margin-top: 0px;
  }

  .userDlImg {
    padding: 10px;
    min-height: 52px;
    margin-top: 8px;

    .viewIDocBtn {
      // position: absolute;
      z-index: 99;
      background-color: #fff;
      height: 22px;
      width: 22px;
      border: 1px solid #001489;
      border-radius: 100%;
      // top: 15px;
      background-size: 14px;
    }
  }
}

.userDlImg {
  background: rgba(238, 240, 248, 0.85);
  border: 1px solid #cdd2e1;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  margin-top: 5px;
  min-height: 60px;
  position: relative;

  img {
    max-height: 31px;
    max-width: 48px;
    cursor: pointer;
    margin-left: 8px;
  }

  .dlImgName {
    margin: 0px 0 0 10px;
    word-break: break-all;
    line-height: 18px;
  }

  .deleteDoc {
    background: url("../../../Assets/Images/delete-red.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 14px !important;
    background-color: #f4d9d9 !important;
    width: 30px !important;
    height: 30px !important;
    display: inline-block !important;
    border: none !important;
    border-radius: 100% !important;
    top: 13px !important;
    right: 20px !important;
  }

  .downloadDoc {
    background: url("../../../Assets/Images/fi_download.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 14px !important;
    background-color: #0e4fae !important;
    width: 30px !important;
    height: 30px !important;
    display: inline-block !important;
    border: none !important;
    border-radius: 100% !important;
    top: 13px !important;
    right: 60px !important;
  }
}

.k-dialog {
  .userDlImg {
    margin-top: 15px;

    .dlImgName {
      max-width: calc(100% - 150px);
      margin-left: 8px;
    }

    img {
      min-width: 32px;
    }
  }
}

.editContactModal {
  .profilePicUploadOuter {
    margin-top: 25px;

    .uploadedProfileImg {
      background-image: url("../../../Assets/Images/upload-circle-icon-blue.svg") !important;
    }
  }
}

.customSmallModel {
  .profilePicUploadOuter {
    .importDocImg:empty {
      background-image: url("../../../Assets/Images/import-gray-circle-img.svg") !important;
    }
  }
}

.profileSortInfoToggleBtn {
  background: url("../../../Assets/Images/down-angle-black.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  display: inline-block;
  padding: 0;
  position: absolute;
  right: 0px;
  bottom: -5px;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);

  &:focus {
    box-shadow: none;
  }
}

.k-selected.profileSortInfoToggleBtn {
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  background-color: transparent;
  border: none;
}

.selectNewBroker {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: $dark-grey;
  margin-bottom: -18px;

  strong {
    padding-right: 5px;
  }
}

.brokerSelection {
  background: rgba(238, 240, 248, 0.85);
  border-radius: 4px;
  padding: 8px 5px 10px 20px;

  .bselectionHead {
    border-bottom: 1px solid #dee0ef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px 8px 0;

    label {
      font-weight: 500;
      font-size: 11px;
      line-height: 10px;
      color: #919aa9;

      &:last-child {
        text-align: right;
      }
    }
  }

  .brokerSelectionList {
    height: 350px !important;

    .brokerSelectionListInner {
      padding-right: 15px;
    }

    .brokerSelectRow {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: $dark-grey;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 18px 0;

      .brokerName {
        display: flex;
        align-items: center;
        width: 50%;

        label {
          padding-left: 12px;
          width: calc(100% - 20px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .currentAssign {
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 20px;
        text-align: right;
      }
    }
  }
}

.dataListViewWrapper {
  .tblColData-hidden-md {
    display: none;
  }

  .promotionsListTbl {
    .k-grid-header {
      table {
        tr {
          th:nth-child(1),
          th:nth-child(6),
          th:nth-child(7),
          th:last-child {
            .k-link:after {
              display: none !important;
            }
          }
        }
      }
    }
  }

  .k-detail-row {
    .k-detail-cell {
      padding: 0;
      white-space: normal;

      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: $label-color;
        margin-bottom: 10px;
        display: block;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $dark-grey;
        margin-bottom: 0;

        & + p {
          margin-top: 15px;
        }

        div {
          margin-bottom: 4px;
          display: block;
          min-height: 16px;

          .viewDocBtn {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 14px;
            width: 17px;
            height: 14px;
            display: inline-block;
            padding: 0;
            background-image: url("../../../Assets/Images/eye-blue.svg");
            margin-left: 5px;
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }

  .k-grid.promotionsListTbl {
    table {
      colgroup {
        col:nth-child(1) {
          width: 40px;
        }

        col:nth-child(2) {
          width: 22%;
        }

        col:nth-child(3) {
          width: 13%;
        }

        col:nth-child(4) {
          width: 13%;
        }

        col:nth-child(5) {
          width: 12%;
        }

        col:nth-child(6) {
          width: 12%;
        }

        col:nth-child(7) {
          width: 11%;
        }

        col:nth-child(8) {
          width: 8%;
        }

        col:last-child {
          width: 120px;
        }
      }
    }

    .k-grid-header .k-header:first-child,
    .k-master-row td:first-child {
      padding-right: 0;
    }

    .k-grid-header .k-header:last-child {
      .k-cell-inner .k-link {
        justify-content: center;

                .k-column-title {
                    padding-right: 0;
                    display: none;
                }
            }
        }
    }

  .k-grid.passengerListTbl {
    .k-grid-header {
      table {
        tr {
          th:nth-child(1),
          th:last-child {
            .k-link:after {
              display: none !important;
            }
          }
        }
      }
    }

    table {
      min-width: 900px;

      colgroup {
        col:nth-child(1) {
          width: 30px;
        }

        col:nth-child(2) {
          width: 12%;
        }

        col:nth-child(3) {
          width: 15%;
        }

        col:nth-child(4) {
          width: 18%;
        }

        col:nth-child(5) {
          width: 16%;
        }

        col:nth-child(6) {
          width: 13%;
        }

        col:nth-child(7) {
          width: 12%;
        }

        col:last-child {
          width: 60px;
        }
      }

      .k-master-row {
        .gridActions {
          .deleteIconBtn {
            background-image: url("../../../Assets/Images/delete-red.svg") !important;
          }
        }
      }
    }
  }

  .k-grid.jetCardAgreementsTbl {
    .k-grid-header {
      table {
        tr {
          th:nth-child(2),
          th:nth-child(5),
          th:nth-child(6),
          th:nth-child(8),
          th:nth-child(9),
          th:last-child {
            .k-link:after {
              display: none !important;
            }
          }
        }
      }
    }

    table {
      colgroup {
        col:nth-child(1) {
          width: 30px;
        }

        col:nth-child(2) {
          width: 8%;
        }

        col:nth-child(3) {
          width: 16%;
        }

        col:nth-child(4) {
          width: 8%;
        }

        col:nth-child(5) {
          width: 10%;
        }

        col:nth-child(6) {
          width: 10%;
        }

        col:nth-child(7) {
          width: 10%;
        }

        col:nth-child(8) {
          width: 10%;
        }

        col:nth-child(9) {
          width: 12%;
        }

        col:nth-child(10) {
          width: 7%;
        }

        col:last-child {
          width: 50px;
        }
      }
    }
  }
  .k-grid.aircraftTbl {
    .k-grid-header {
      table {
        th:last-child {
          .k-link:after {
            display: none !important;
          }
        }
      }
    }

    table {
      colgroup {
        col:nth-child(1) {
          width: 3px;
        }

        col:nth-child(2) {
          width: 20%;
        }

        col:nth-child(3) {
          width: 16%;
        }

        col:nth-child(4) {
          width: 8%;
        }

        col:nth-child(5) {
          width: 20%;
        }

        col:nth-child(6) {
          width: 20%;
        }

        col:nth-child(7) {
          width: 10%;
        }

        col:nth-child(8) {
          width: 10%;
        }

        col:nth-child(9) {
          width: 12%;
        }

        col:nth-child(10) {
          width: 7%;
        }

        col:last-child {
          width: 50px;
        }
      }
    }
  }
  .k-grid.operatorTbl {
    .k-grid-header {
      table {
        th:first-child,
        th:nth-child(5),
        th:nth-child(9),

        th:last-child {
            .k-link:after {
                display: none !important;
            }
        }
    }
    }

    table {
      colgroup {
        col:nth-child(1) {
          width: 2%;
        }

        col:nth-child(2) {
          width: 13%;
        }

        col:nth-child(3) {
          width: 7%;
        }

        col:nth-child(4) {
          width: 13%;
        }

        col:nth-child(5) {
          width: 9%;
        }

        col:nth-child(6) {
          width: 6%;
        }

        col:nth-child(7) {
          width: 14%;
        }

        // col:nth-child(8) {
        //   width: 7%;
        // }

        col:nth-child(8) {
          width: 7%;
        }

        col:nth-child(9) {
          width: 12%;
        }

        col:nth-child(10){
          width:10%;
        }
        col:last-child {
          width: 7%;
        }
      }
    }
  }
  .k-grid.airportTbl {
    .k-grid-header {
      table {
        th:nth-child(4),
        th:nth-child(6),
        th:nth-child(7),
        th:nth-child(8),
        th:last-child {
          .k-link:after {
            display: none !important;
          }
        }
      }
    }

    table {
      colgroup {
        col:nth-child(1) {
          width: 5%;
        }

        col:nth-child(2) {
          width: 20%;
        }

        col:nth-child(3) {
          width: 25%;
        }

        col:nth-child(4) {
          width: 20%;
        }

        col:nth-child(5) {
          width: 18%;
        }

        col:nth-child(6) {
          width: 18%;
        }

        col:nth-child(7) {
          width: 15%;
        }

        col:nth-child(8) {
          width: 10%;
        }

        col:nth-child(9) {
          width: 12%;
        }

        col:nth-child(10) {
          width: 7%;
        }

        col:last-child {
          width: 12%;
        }
      }
    }
  }
  .k-grid.airportFBO {
    .k-grid-header {
      table {
        th:last-child,
        th:nth-child(3),
        th:nth-child(5),
        th:nth-child(4) {
          .k-link:after {
            display: none !important;
          }
        }
      }
    }

    table {
      colgroup {
        col:nth-child(1) {
          width: 5%;
        }

        col:nth-child(2) {
          width: 10%;
        }

        col:nth-child(3) {
          width: 30%;
        }

        col:nth-child(4) {
          width: 25%;
        }

        col:nth-child(5) {
          width: 14%;
        }

        col:nth-child(6) {
          width: 15%;
        }

        col:nth-child(7) {
          width: 20%;
        }

        col:nth-child(8) {
          width: 10%;
        }

        col:nth-child(9) {
          width: 12%;
        }

        col:nth-child(10) {
          width: 7%;
        }

        col:last-child {
          width: 12%;
        }
      }
    }
  }

  .k-grid-header .k-header:first-child,
  .k-master-row td:first-child {
    padding-right: 0;
  }

  .k-grid-header .k-header:last-child {
    .k-cell-inner .k-link {
      // justify-content: center;

      .k-column-title {
        padding-right: 0;
      }
    }
  }

    .k-grid.aircraftTblNew {
        .k-grid-header {
            table {
                th:first-child,
                th:nth-child(6),
                th:nth-child(11),
                th:last-child {
                    .k-link:after {
                        display: none !important;
                    }
                }
            }
        }

        table {
            colgroup {
                col:nth-child(1) {
                    width: 2%;
                }

                col:nth-child(2) {
                    width: 3%;
                }

                col:nth-child(3) {
                    width: 13%;
                }

                col:nth-child(4) {
                    width: 7%;
                }

                col:nth-child(5) {
                    width: 8%;
                }

                col:nth-child(6) {
                    width: 10%;
                }
                col:nth-child(7) {
                    width: 7%;
                }
                col:nth-child(8) {
                    width: 8%;
                }
                col:nth-child(9) {
                    width: 9%;
                }
                col:nth-child(10) {
                    width: 7%;
                }
                col:nth-child(11) {
                    width: 9%;
                }
                col:nth-child(12) {
                    width: 7%;
                }
                col:last-child {
                    width: 7%;
                }
            }
        }
    }
}

.promotionLabel {
  @extend .selectNewBroker;
  color: $lighter-grey;
  margin-top: 20px;
}

.addPromotionModel {
  .maverickForm {
    padding-right: 15px;
    // [aria-hidden="true"] { display: none; }
  }
}

.promotionRadioBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  .btnTypeRadio {
    position: relative;
    width: 100%;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    label {
      background: #fff;
      height: 70px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-weight: 400;
      font-size: 18px;
      cursor: pointer;
      padding: 0 10px;
      border: 1px solid;
      color: $dark-grey;
      border-radius: 4px;
      transition: all 0.3s ease;
      margin-left: 0;
      opacity: 0.4;
    }

    input:checked ~ label {
      background: #e5e7f1;
      border: 1px solid $primary-light-blue;
      color: $primary-light-blue;
      opacity: 1;
    }
  }
}

.promotionValuCol {
  max-width: 150px;
}

.tooltipTblData {
  .tooltipRow {
    font-size: 12px;
    line-height: 22px;
    color: $dark-grey;
    text-align: left;

    label {
      font-weight: 600;
      margin: 0;
      padding-right: 3px;
    }

    span {
      font-weight: 400;
    }
  }
}

// Jet Cards Details start
.jetCardDetailsExpandedSec {
  .k-panelbar {
    border: none;
    background: transparent;

    .k-panelbar-header {
      margin-top: 5px;
      margin-bottom: 15px;
      background: #ffffff;
      border: 0.5px solid rgba(210, 218, 227, 0.75);
      border-radius: 5px;
      padding: 20px;

      .k-cell-inner {
        margin-left: 0;
      }

      .k-link {
        padding: 0;
        margin-bottom: 0;
        cursor: pointer;

        &:focus,
        &:hover {
          box-shadow: none;
          background-color: transparent;
        }

        .k-icon {
          color: $dark-blue;
        }
      }

      .k-focus {
        box-shadow: none;
      }

      .k-selected {
        background-color: #fff !important;
        color: $dark-grey;
        box-shadow: none !important;
        border: none;
      }
    }
  }

  .k-panelbar-item-text {
    width: 100%;
  }

  .overviewTitleGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .otgLeft {
      p {
        margin: 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 18px;
        color: $dark-grey;
        letter-spacing: initial;
        text-transform: initial;

        br {
          display: none;
        }

        strong {
          font-weight: 500;
        }

        .jc-title-panel {
          input,
          input::placeholder {
            background-color: transparent;
            border: none;
            border-bottom: 1.5px solid #001489;
            line-height: 13px;
            outline: none;
          }
        }

        span {
          border-right: 2px solid $dark-grey;
          padding-right: 15px;
          margin-left: 5px;
          margin-right: 10px;
        }

        .jcStatus {
          position: relative;
          border: none;
          padding-right: 0;
          margin-right: 0;
          margin-left: 5px;

          &::before {
            content: "";
            margin-right: 8px;
            width: 8px;
            height: 8px;
            display: inline-block;
            border-radius: 100%;
          }
        }

        // JC Agreement Status
        .live::before {
          background-color: $success-color;
        }

        .paymentDue::before {
          background-color: $warning-color;
        }

        .rejected::before {
          background-color: $error-color;
        }

        .sent::before {
          background-color: $primary-light-blue;
        }

        .draft::before {
          background-color: #9bcbeb;
        }

        .exhausted::before {
          background-color: #949dd7;
        }

        .inactive::before {
          background-color: $label-color;
        }
      }
    }

    .otgRightAction {
      .hoursLabel {
        font-weight: 500;
        font-size: 12px;
        line-height: 11px;
        text-align: right;
        color: $deep-blue;
        background: #e6e9f7;
        border-radius: 4px;
        padding: 10px 12px 7px 12px;
        margin-right: 10px;
      }
    }
  }

  .clientDetailsBandExpandedData {
    padding-top: 20px;
    flex-direction: column;
    display: flex;

    .jetCardShortInfo {
      margin-bottom: 15px;

      h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $dark-grey;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .editIconBtn {
          border: none;
          background-size: 14px !important;
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }

      .shortDetailsWrapper {
        background: rgba(238, 240, 248, 0.85);
        border-radius: 4px;
        display: inline-block;
        width: 100%;
        padding: 12px 20px 5px 20px;

        .width-15 {
          width: 15%;
        }

        .width-10 {
          width: 10%;
        }

        .width-30 {
          width: 30%;
        }

        @media (max-width: 767px) {
          .width-15,
          .width-10,
          .width-30 {
            width: 50%;
          }
        }

        ul {
          list-style: none;
          margin: 0 -12px;
          padding: 0;
          width: 100%;

          li {
            float: left;
            width: calc(100% / 7);
            padding: 0 12px;
          }
        }

        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 11px;
          color: $label-color;
          margin-bottom: 10px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          color: $dark-blue;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          gap: 5px;

          img {
            width: 13px;
            margin-top: -4px;
          }
        }
      }
    }

    .billingHistoryTbl {
      .k-grid-header {
        background: transparent;

        table tr th .k-link:after {
          display: none !important;
        }
      }

      table {
        min-width: 100%;

        colgroup {
          col:nth-child(1) {
            width: 13%;
          }

          col:nth-child(2) {
            width: 11%;
          }

          col:nth-child(3) {
            width: 13%;
          }

          col:nth-child(4) {
            width: 17%;
          }

          col:nth-child(5) {
            width: 12%;
          }

          col:nth-child(6) {
            width: 10%;
          }

          col:nth-child(7) {
            width: 11%;
          }

          col:nth-child(8) {
            width: 10%;
          }

          col:last-child {
            width: 5%;
          }
        }

        .k-master-row td:first-child {
          text-align: left;
          padding: 12px 10px;
        }
      }

      .unionBtn {
        background-image: url("../../../Assets/Images/union-icon-blue.svg") !important;
      }

      .nofundsec {
        max-width: 600px;
        margin: 0 auto;
        padding: 40px;

        img {
          width: 36px;
          margin-bottom: 15px;
        }

        p {
          color: $dark-grey;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }

        .addRoleBtn {
          justify-content: center;

          .btn-link {
            color: $primary-light-blue;
          }
        }
      }
    }
  }

  .addNewJetcardProposal {
    background-color: rgba(215, 218, 235, 0.24);
    border: 1px dashed rgba(0, 20, 137, 0.4);
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    color: $primary-dark-blue;
    background-image: url("../../../Assets/Images/pluse-circle-blue.svg");
    padding: 20px;
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: center right 20px;
    cursor: pointer;
  }
}

.popoverRateText {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  color: $dark-grey;
}

.popoverRateTitle {
  font-size: 14px;
  font-weight: 600;
}

.preferred {
  color: $primary-dark-blue;
}

@media screen and (max-width: 992px) {
  .userDetailsWrapper .userSortInfoCard {
    //margin-bottom: 25px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 992px) {
  .profileSortInfoToggleBtn {
    display: none;
  }
}

/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {
  .addContactDetailsModel {
    .k-overlay {
      display: none;
    }

    .k-dialog {
      top: 60px;
      max-width: 100%;

      .k-dialog-titlebar {
        padding: 15px 20px 15px 15px;

        h4 {
          font-size: 14px;
        }
      }

      .k-window-content {
        padding: 0px 2px 15px 15px;

        .contactsPanels .k-panelbar {
          padding-right: 13px;
        }
      }

      .modelFooterAction {
        padding: 15px;
        justify-content: center;

        .k-button-flat-primary,
        .hideBtnMob {
          display: none;
        }

        .k-button-solid-primary {
          width: 100%;
          font-weight: 600;
        }
      }
    }

    .contactsPanels {
      padding-bottom: 0;
      height: calc(100% - 60px) !important;

      .maverickForm .form-group .k-floating-label-container,
      .maverickForm .contactRadio {
        margin-top: -8px;
      }
    }
  }

  .addContactDetailsModel .contactsPanels .maverickForm .checkListSec {
    padding-top: 15px;
  }

  .contactTopTitleSec .titleSec h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #00041b;
    display: flex;
    align-items: center;

    .backScreen {
      margin-right: 5px;
      margin-top: -4px;
    }
  }

  .userDetailsWrapper {
    .userSortInfoCard {
      padding: 12px;

      .userPicTitleSec {
        position: relative;

        .uploadUserPic {
          img {
            max-width: 48px;
            max-height: 48px;
          }

          .uploadProfilePic {
            background-size: 13px;
            width: 22px;
            height: 22px;
          }
        }

        .uploadTopInfoSec {
          max-width: calc(100% - 50px);
          position: relative;
          width: calc(100% - 50px);

          h4 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0;
          }

          .leadBtn {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }

      .userContactInfo {
        p {
          margin-bottom: 10px;

          label {
            font-size: 15px;
            line-height: 20px;
          }

          img {
            width: 14px;
            max-height: 14px;
          }
        }
      }

      .userTripInfoSec {
        p {
          font-size: 15px;

          &:last-child {
            margin-bottom: 5px;
          }
        }
      }
    }

    .userDetailSec {
      margin-bottom: 20px;

      .userTitleSec {
        margin: 10px 0 14px 0;
        position: relative;

        h4 {
          font-size: 20px;
          line-height: 18px;
        }

        .editSecBtn {
          position: absolute;
          bottom: -50px;
          right: 7px;
        }
      }

      .userSecData {
        padding: 8px 15px 0px 15px;

        p {
          line-height: 18px;
          margin: 2px 0 15px 0;
        }

        .userDlImg {
          margin-bottom: 15px;

          .dlImgName {
            margin: 0 0 0 12px;
          }
        }
      }
    }

    .pinnedSec {
      display: none;
    }

    .userProfileSec {
      padding: 20px;

      .userPicTitleSec {
        border-bottom: 1px solid #dde1f6;
        border-right: none;
        padding-bottom: 20px;

        .uploadUserPic {
          img {
            width: 80px;
          }

          .uploadProfilePic {
            background-size: 16px !important;
            width: 30px !important;
            height: 30px !important;
            border-width: 1px !important;
          }
        }

        .userNameId {
          padding-left: 15px;

          h1 {
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 5px;
          }

          p {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }

      .userMoreDetails {
        padding-left: 0;
        padding-top: 15px;

        label {
          margin-bottom: 8px;
        }

        p {
          font-size: 16px;
          line-height: 15px;
          margin-bottom: 10px;
        }
      }
    }

    .updateProfileInfo {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
    }

    .screenTitle {
      padding-top: 20px;
    }
  }

  .addContactDetailsModel .contactsPanels .maverickForm {
    h4 {
      font-size: 16px;
      margin-bottom: 5px !important;
    }

    p {
      font-size: 12px;
    }
  }

  .customSmallModel,
  .uploadProfileImgModel {
    .k-dialog {
      margin: 0 auto;
      max-width: calc(100% - 25px) !important;

      .k-window-content {
        padding: 20px;
      }

      .k-dialog-titlebar {
        padding: 15px 20px 0 20px;
      }

      .modelFooterAction {
        padding-top: 20px;
        justify-content: center;

        .k-button-flat {
          display: none;
        }

        .k-button-solid {
          min-width: inherit;
        }
      }

      .profilePicUploadOuter .uploadImageHints {
        top: 20px;

        h4 {
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 8px !important;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }

  .selectNewBroker {
    margin-bottom: -10px;
  }

  .contactPageTitleSec {
    margin: 10px 0;

    h3 {
      font-size: 20px;
      line-height: 18px;
    }

    .addRoleBtn .btn-link {
      background-size: 16px;
      padding-left: 22px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  .dataListViewWrapper {
    .k-detail-row {
      .k-hierarchy-cell {
        display: none;
      }

      .k-detail-cell {
        .tableDetailsData {
          padding: 15px 20px 5px 20px;

          label {
            margin-bottom: 6px;
          }

          p {
            margin-bottom: 15px;

            div:last-child {
              margin-bottom: 0;
            }
          }

          .linkCol {
            color: $primary-light-blue;
            font-weight: 600;
          }

          .tblColData-hidden-md {
            display: block;

            hr {
              border-color: #e2e7f0;
              opacity: 1;
              margin-top: 0;
            }

            .promotionTooltipData {
              p {
                margin-bottom: 3px;
              }

              p + p {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .contactTopTitleSec
    .rightBtnGroup
    .moreActionLinks
    .dropdown-menu.menuRightMob {
    right: 0;
  }

  .jetCardDetailsExpandedSec {
    .k-panelbar .k-panelbar-header {
      margin-top: 0px;
      margin-bottom: 15px;
      padding: 15px;

      .overviewTitleGroup {
        .otgRightAction {
          display: flex;
          padding-left: 5px;
          align-items: center;

          .hoursLabel {
            padding: 7px 6px 4px 6px;
            margin-right: 0px;
            white-space: nowrap;
          }
        }

        .otgLeft p {
          font-size: 14px;
          line-height: 20px;

          br {
            display: block;
          }

          span {
            border-width: 1px;
            padding-right: 5px;
            margin-left: 5px;
            margin-right: 0px;
          }

          .cardName {
            border: none;
          }

          .displayName {
            margin-left: 0px;
          }
        }
      }
    }

    .clientDetailsBandExpandedData {
      padding-top: 15px;

      .jetCardShortInfo .shortDetailsWrapper {
        padding: 8px 15px 4px 15px;

        ul {
          width: calc(100% + 24px);

          li {
            width: 50%;
          }
        }

        label {
          margin-bottom: 5px;
        }

        p {
          font-size: 15px;
          margin-bottom: 10px;
        }
      }

      .customTab .k-tabstrip-items-wrapper {
        margin-bottom: 8px;
      }

      .billingHistoryTbl .nofundsec {
        max-width: 90vw;
        margin: 0;
        padding: 20px;
      }
    }

    .billingHistoryTbl table {
      min-width: 1000px !important;
    }

    .addNewJetcardProposal {
      font-size: 16px;
      padding: 18px;
      background-size: 18px;
      background-position: center right 18px;
    }
  }
}

.navigateLinkText {
  color: #02126b;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.navigateLinkText:hover {
  color: #00041b;
}

// Hide Show columns on mobile view
@media screen and (max-width: 767px) {
  //All Passengers Table
  .k-grid.passengerListTbl {
    table {
      min-width: 100% !important;

      col:nth-child(4),
      th:nth-child(4),
      td:nth-child(4),
      col:nth-child(5),
      th:nth-child(5),
      td:nth-child(5),
      col:nth-child(6),
      th:nth-child(6),
      td:nth-child(6),
      col:nth-child(7),
      th:nth-child(7),
      td:nth-child(7) {
        display: none !important;
      }

      colgroup {
        col:nth-child(1) {
          width: 30px !important;
        }

        col:nth-child(2) {
          width: 50% !important;
        }

        col:nth-child(3) {
          width: 50% !important;
        }

        col:last-child {
          width: 70px !important;
        }
      }
    }
  }

  // JC Promotions Table
  .k-grid.promotionsListTbl {
    table {
      min-width: 100% !important;

      col:nth-child(3),
      th:nth-child(3),
      td:nth-child(3),
      col:nth-child(4),
      th:nth-child(4),
      td:nth-child(4),
      col:nth-child(5),
      th:nth-child(5),
      td:nth-child(5),
      col:nth-child(6),
      th:nth-child(6),
      td:nth-child(6),
      col:nth-child(7),
      th:nth-child(7),
      td:nth-child(7) {
        display: none !important;
      }

      colgroup {
        col:nth-child(1) {
          width: 35px !important;
        }

        col:nth-child(2) {
          width: 50% !important;
        }

        col:nth-child(8) {
          width: 30% !important;
        }

        col:last-child {
          width: 110px !important;
        }
      }
    }
  }
}

.tripStatusText.Accepted {
  background-color: $success-color;
}

.tripStatusText.AssignmentMade {
  background-color: $primary-light-blue;
}

.tripStatusText.Finalized {
  background-color: $deep-blue;
}

.tripStatusText.Cancelled {
  background-color: $error-color;
}

.tripStatusText.Draft {
  background-color: #b4b6c3;
}

.tripStatusText.SchedulingPricingProposal {
  background-color: $warning-color;
}

.tripStatusText.QuoteReview {
  background-color: #fde50e;
}

.tripStatusText.ClientReview {
  background-color: $info-color;
}

.tripStatusText.PartiallyAssigned {
  background-color: #ffffff;
  border: 1px solid $primary-light-blue;
}

.tripStatusText.OngoingTrip {
  background-color: #9bcbeb;
}

.tripStatusText.Scheduling {
  background-color: #ffa722;
}

.tripStatusText.FinanceReview {
  background-color: #949dd7;
}

.tripStatusText.TripReview {
  background-color: #fccf8c;
}

.passengersDocumentText {
  height: 20px;
}
.addContactDetailsModel.width-700 {
  .k-dialog {
    max-width: 800px;
    .maverickForm{
      max-width: 100%;
    }
  }
}

.error-red {
  color: red;
  position: relative;
  top: -1px;
}
